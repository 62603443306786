import React, { useReducer } from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Input from '../components/Input';
import Button from '../components/Button';
import Loader from '../components/Loader';

const re =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
const isValidEmail = email => {
    if (email.match(re)) {
        return true;
    }
    return false;
};

const EliminarCuentaPage = () => {
    const [state, setState] = useReducer((s, a) => ({ ...s, ...a }), {
        email: null,
        error: null,
        loading: false,
        resolved: false,
    });

    const addEmailToList = e => {
        e.preventDefault();

        if (state.email && isValidEmail(state.email)) {
            setState({
                ...state,
                loading: true,
                error: null,
            });
            var myHeaders = new Headers();
            myHeaders.append(
                'Authorization',
                'Bearer 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',
            );
            myHeaders.append('Content-Type', 'application/json');
            const contactListId = '357e50d2-b8cd-4270-935e-3550a65f4d95';

            var raw = JSON.stringify({
                list_ids: [contactListId],
                contacts: [{ email: state.email }],
            });

            var requestOptions = {
                method: 'PUT',
                headers: myHeaders,
                body: raw,
                redirect: 'follow',
            };

            fetch('https://api.sendgrid.com/v3/marketing/contacts', requestOptions)
                .then(response => response.text())
                .then(result => {
                    setState({
                        ...state,
                        loading: false,
                        resolved: true,
                        error: null,
                    });
                })
                .catch(error => {
                    setState({
                        ...state,
                        loading: false,
                        resolved: false,
                        error,
                    });
                });
        } else {
            // show email error
            setState({
                ...state,
                error: 'Completa los campos de nombre y email para continuar.',
            });
        }
    };
    return (
        <Layout>
            <SEO
                title="Aguaman | Primera recarga"
                pathname="/eliminar-cuenta"
                image="https://aguamandelivery.com/static/primera-recarga-4b4ae40af17517dd322211fc414386c7.jpg"
            />
            {/* <Newsletter /> */}
            <section className="cta primera-recarga-cta">
                <h1 style={{ color: '#00a4d3' }}>Eliminar cuenta de Aguaman</h1>
                <div>
                    <p style={{ fontSize: '1rem' }}>
                        Borraremos tu cuenta de nuestra base de datos y toda la información
                        relacionada:
                        <br />
                        <br />
                        <strong>Nombre completo</strong>
                        <br />
                        <strong>Número de teléfono</strong>
                        <br />
                        <strong>Email</strong>
                        <br />
                        <strong>Dirección de entrega</strong>
                        <br />
                    </p>
                    <p style={{ fontSize: '1rem' }}>
                        Te enviaremos un correo electrónico para confirmar que quieres eliminar tu
                        cuenta y datos asociados
                    </p>
                    <form onSubmit={addEmailToList} className="primera-recarga-form-email">
                        <Input
                            type="email"
                            placeholder="Tu email"
                            value={state.email || ''}
                            onChange={e =>
                                setState({ ...state, error: null, email: e.target.value })
                            }
                            disabled={state.loading || state.resolved}
                        />
                        <Button
                            type="submit"
                            className="button button-success"
                            label="Eliminar cuenta"
                            onClick={addEmailToList}
                            disabled={state.loading || state.resolved}
                        />
                    </form>
                    <div>
                        <p style={{ color: 'green', fontWeight: 'bold' }}>
                            {state.resolved &&
                                '✅ Te enviamos un correo para confirmar la eliminación de tu cuenta'}
                        </p>
                        {state.loading && <Loader />}
                        <p style={{ fontSize: '1rem' }}>{state.error}</p>
                    </div>
                    <br />
                </div>
            </section>
        </Layout>
    );
};

export default EliminarCuentaPage;
